import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { SubmitHandler, useForm } from "react-hook-form";
import { Button, Modal } from "react-bootstrap";
import moment from "moment";
import { UserType } from "../../_App/Types/Entites/UserType"; // Adjust the import path as necessary
import {
  currentSousSociete,
  users,
  userSousSociete,
} from "../../_App/Redux/Slices/auth/authSlice";
import { toast } from "react-toastify";
import {
  ListRoles,
  saveUserAPi,
  updateUser,
} from "../../_App/Redux/Slices/users/userSlice";
import { generateCode } from "../../_App/Helpers/helpers";
import { files, saveFile } from "../../_App/Redux/Slices/files/filesSlice";

const UserForm = ({
  selectedUser,
  showModal,
  setShowModal,
  setSelectedUser,
  load,
  setLoad,
}: any) => {
  const { t } = useTranslation();
  const currentSousSte = useAppSelector(currentSousSociete);
  const usersList = useAppSelector(users);
  const sousSoucietes = useAppSelector(userSousSociete);
  const roles = useAppSelector(ListRoles);

  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<UserType>();

  useEffect(() => {
    if (selectedUser) {
      reset(selectedUser);
    } else {
      reset({
        code: generateCode(usersList, ""),
        nom: "",
        prenom: "",
        grade: "",
        only_my_data: 0,
        vente_comptoir: 0,
        matricule: "",
        adresse: "",
        login: "",
        password: "",
        email: "",
        email_verifie: 0,
        telephone: "",
        mobile: "",
        isactif: 1,
        isadmin: 0,
        equipe: "",
        gamme: "",
        soussociete_code: currentSousSte,
        code_a_barre: "",
        dashboard: null,
        prixachat: "",
        salaire_base: 0,
        famille: 0,
        enfant: 0,
        ordre: 0,
      });
    }
  }, [selectedUser, showModal]);

  const closeModal = () => {
    if (setSelectedUser !== null) setSelectedUser(undefined);
    setShowModal(false);
  };

  const [file, setFile] = useState<File | null>(null);
  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };
  const uploadFile = async () => {
    if (!file) return "";

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}files/upload`,
        {
          method: "POST",
          body: formData,
        }
      );
      const data = await response.json();
      if (data.success) {
        console.log("================>", data);
        return data.fileName;
      } else {
        toast.error("File upload failed");
        return "";
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      toast.error("Error uploading file");
      return "";
    }
  };
  const docs = useAppSelector(files);

  const onSubmit: SubmitHandler<UserType> = async (data) => {
    if (selectedUser) {
      try {
        dispatch(updateUser({ id: selectedUser.id, data: data }));
        setLoad(!load);
        closeModal();
      } catch (error) {
        console.error("Error updating user:", error);
      }
    } else {
      let res = await dispatch(saveUserAPi(data));
      if (res.payload.success) {
        const fileName = await uploadFile(); // Await file upload

        let fileData = {
          code: res.payload.data.code,
          type: "user_docs",
          path: fileName, // Use the full URL path returned by uploadFile
          // path: process.env.REACT_APP_API_URL_UPLOADS + fileName,
        };
        dispatch(saveFile(fileData));
        setLoad(!load);
        closeModal();
        toast.success("Utilisateur ajouté avec succès !");
      }
    }
  };

  return (
    <Modal show={showModal} onHide={closeModal}>
      <Modal.Header>
        <Modal.Title>
          <span className="modal-title" id="addUserLabel">
            <i className="mdi mdi-plus-circle text-primary mr-1" />
            {selectedUser ? t("MAJ Utilisateur") : t("Nouvel Utilisateur")}
          </span>
        </Modal.Title>
        <button
          type="reset"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={() => closeModal()}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className="card-body">
          <form onSubmit={handleSubmit(onSubmit)}>
            {!selectedUser ? (
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <label htmlFor="file">{t("Fichier")}</label>
                    <input
                      type="file"
                      className="form-control"
                      onChange={onFileChange}
                    />
                  </div>
                </div>
              </div>
            ) : null}
            {selectedUser &&
              docs &&
              docs[selectedUser.code] &&
              docs[selectedUser.code][0] && (
                <div className="row mb-3">
                  <div className="col-12">
                    <a
                      href={
                        process.env.REACT_APP_API_URL_UPLOADS +
                        docs[selectedUser.code][0].path
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t("Télécharger le fichier associé")}
                    </a>
                  </div>
                </div>
              )}
            <div className="row">
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="nom">
                    {t("Nom")}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    {...register("nom", { required: true })}
                  />
                  {errors.nom && (
                    <span className="text-danger">
                      {t("Ce champ est requis.")}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="prenom">
                    {t("Prénom")}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    {...register("prenom", { required: true })}
                  />
                  {errors.prenom && (
                    <span className="text-danger">
                      {t("Ce champ est requis.")}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="matricule">
                    {t("Matricule")}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    {...register("matricule", { required: true })}
                  />
                  {errors.matricule && (
                    <span className="text-danger">
                      {t("Ce champ est requis.")}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="adresse">
                    {t("Adresse")}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    {...register("adresse", { required: true })}
                  />
                  {errors.adresse && (
                    <span className="text-danger">
                      {t("Ce champ est requis.")}
                    </span>
                  )}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="email">
                    {t("Email")}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    {...register("email", { required: true })}
                  />
                  {errors.email && (
                    <span className="text-danger">
                      {t("Ce champ est requis.")}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="telephone">
                    {t("Téléphone")}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    {...register("telephone", { required: true })}
                  />
                  {errors.telephone && (
                    <span className="text-danger">
                      {t("Ce champ est requis.")}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="mobile">
                    {t("Mobile")}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    {...register("mobile", { required: true })}
                  />
                  {errors.mobile && (
                    <span className="text-danger">
                      {t("Ce champ est requis.")}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label>{t("Sous-societé")}</label>
                  <select
                    className="form-control w-100 "
                    style={{ height: "40px" }}
                    {...register("soussociete_code")}
                  >
                    {sousSoucietes.map((items, keys) => (
                      <option value={items.id} key={keys}>
                        {items.nom}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <label>{t("Role")}</label>
                  <select
                    className="form-control w-100 "
                    style={{ height: "40px" }}
                    {...register("role_code")}
                  >
                    {roles &&
                      Array.isArray(roles) &&
                      roles?.map((items, keys) => (
                        <option value={items.code} key={keys}>
                          {items.libelle}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            </div>

            <Button
              variant="primary"
              type="submit"
              className="mr-2 float-right"
            >
              {t("Enregistrer")}
            </Button>
            <Button
              variant="secondary"
              className="mr-2 float-right"
              onClick={() => closeModal()}
            >
              {t("Annuler")}
            </Button>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UserForm;
