import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PayHistoryType } from "../../../Types/Entites/PayHistoryType";
import axios from "axios";
import { filterObjectsWithDate30DaysLater } from "../../../Helpers/helpers";
import { RootState } from "../../store";
import _ from "lodash";

export const savePayHistory = createAsyncThunk(
  "payHistory/createPayHistory",
  async (data: PayHistoryType, { rejectWithValue }) => {
    try {
      const response = await axios.post("payHistory/createPayHistory", data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

export const findPayHistory = createAsyncThunk(
  "payHistory/findPayHistory",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await axios.get(`payHistory/findPayHistory/${id}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const updatePayHistory = createAsyncThunk(
  "payHistory/updatePayHistory",
  async ({ id, data }: any, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `payHistory/updatePayHistory/${id}`,
        data
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const deletePayHistory = createAsyncThunk(
  "payHistory/deletePayHistory",
  async (id: any, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`payHistory/deletePayHistory/${id}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

interface PayHistoryState {
  payHistoryList: PayHistoryType[];
  payHistoryByCode: { [code: string]: PayHistoryType[] };
  payHistoryByPaidItem: { [code: string]: PayHistoryType[] };
  payHistoryByEmployee: { [employeeId: string]: PayHistoryType[] };
  monthAwayPayHistoryByEmployee: { [employeeId: string]: PayHistoryType[] };
}

const initialState: PayHistoryState = {
  payHistoryList: [],
  payHistoryByCode: {},
  payHistoryByEmployee: {},
  monthAwayPayHistoryByEmployee: {},
  payHistoryByPaidItem: {},
};

export const payHistorySlice = createSlice({
  name: "payHistory",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(savePayHistory.fulfilled, (state, action) => {
      let newPayHistory = action.payload.data;
      state.payHistoryList.splice(0, 0, newPayHistory);
    });

    builder.addCase(findPayHistory.fulfilled, (state, action) => {
      const filteredPayHistory = filterObjectsWithDate30DaysLater(
        action.payload.data
      );

      const groupedByEmployee = _.groupBy(action.payload.data, "employee_id");
      const groupedByCode = _.groupBy(action.payload.data, "id");
      const groupedByPaidItem = _.groupBy(action.payload.data, "paid_id");

      const monthAwayGroupedByEmployee = _.groupBy(
        filteredPayHistory,
        "employee_id"
      );
      return {
        ...state,
        payHistoryList: action.payload.data,
        payHistoryByEmployee: groupedByEmployee,
        monthAwayPayHistoryByEmployee: monthAwayGroupedByEmployee,
        payHistoryByCode: groupedByCode,
        payHistoryByPaidItem: groupedByPaidItem,
      };
    });

    builder.addCase(deletePayHistory.fulfilled, (state, action) => {
      const updatedList = state.payHistoryList.filter(
        (item) => item.id !== action.payload.data.id
      );
      return {
        ...state,
        payHistoryList: updatedList,
      };
    });

    builder.addCase(updatePayHistory.fulfilled, (state, action) => {
      const index = _.findIndex(state.payHistoryList, {
        id: action.payload.data.id,
      });
      if (index !== -1) {
        const updatedList = [...state.payHistoryList];
        updatedList[index] = {
          ...updatedList[index],
          ...action.payload.data,
        };
        return {
          ...state,
          payHistoryList: updatedList,
        };
      }
      return state;
    });
  },
});

export const ListPayHistory = (state: RootState) =>
  state.payHistory.payHistoryList;
export const ListPayHistoryByCode = (state: RootState) =>
  state.payHistory.payHistoryByCode;
export const ListPayHistoryBypaid = (state: RootState) =>
  state.payHistory.payHistoryByPaidItem;
export const PayHistoryByEmployee = (state: RootState) =>
  state.payHistory.payHistoryByEmployee;
export const MonthAwayPayHistoryByEmployee = (state: RootState) =>
  state.payHistory.monthAwayPayHistoryByEmployee;
export default payHistorySlice.reducer;
