import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../_App/Redux/hooks";
import { findPiecesDeRechange } from "../_App/Redux/Slices/piecesRechange/piecesRechangeSlice";
import PiecesDeRechangeList from "../Components/PiecesRechange/PiecesRechangeList";
import PieceDeRechangeForm from "../Components/PiecesRechange/PieceRechangeForm";
import { findEntretiens } from "../_App/Redux/Slices/entretiens/entretienSlice";
import {
  currentSousSociete,
  OnlineUser,
} from "../_App/Redux/Slices/auth/authSlice";
import { findFiles } from "../_App/Redux/Slices/files/filesSlice";

const PiecesDeRechange = () => {
  const dispatch = useAppDispatch();
  const [showModal, setShowModal] = useState(false);
  const [selectedPiece, setSelectedPiece] = useState(undefined);
  const [load, setLoad] = useState(false);
  const currentSousSte = useAppSelector(currentSousSociete);
  const [searchTerm, setSearchTerm] = useState(""); // Add state for search term

  useEffect(() => {
    if (currentSousSte) {
      dispatch(findPiecesDeRechange(currentSousSte));
      dispatch(findEntretiens(currentSousSte));
      dispatch(findFiles(null));
    }
  }, [dispatch, load, currentSousSte]);

  return (
    <div>
      <nav className="navbar search-bar">
        <a href="#" className="sidebar-toggler">
          <i data-feather="menu" />
        </a>
        <div className="navbar-content">
          <form className="search-form">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">
                  <i data-feather="search" />
                </div>
              </div>
              <input
                type="text"
                className="form-control"
                id="navbarForm"
                placeholder="Chercher ..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)} // Update search term state
              />
            </div>
          </form>
        </div>
      </nav>
      <nav className="page-breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="#">Pièces de Rechange</a>
          </li>
        </ol>
      </nav>
      <div className="row content-card">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <i className="mdi mdi-tune-vertical text-primary " /> Liste des
              Pièces de Rechange{" "}
              <button
                className="btn btn-primary float-right"
                onClick={() => setShowModal(true)}
              >
                +
              </button>
            </div>
            <PiecesDeRechangeList
              searchTerm={searchTerm} // Pass search term as a prop
              selectedPiece={selectedPiece}
              setSelectedPiece={setSelectedPiece}
              setShowModal={setShowModal}
              load={load}
              setLoad={setLoad}
            />
            <PieceDeRechangeForm
              showModal={showModal}
              setShowModal={setShowModal}
              selectedPiece={selectedPiece}
              setSelectedPiece={setSelectedPiece}
              load={load}
              setLoad={setLoad}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PiecesDeRechange;
