import React, { useState } from "react";
import { useAppSelector } from "../../_App/Redux/hooks";
import { ListPayHistoryBypaid } from "../../_App/Redux/Slices/payHistory/payHistorySlice";

const VehicleTimelineHistory = ({ data }: { data: any }) => {
    const payments = useAppSelector(ListPayHistoryBypaid);

  
 

  return (
    <div className="page">
      <div
        className="uia-timeline"
        data-uia-timeline-skin="2"
        data-uia-timeline-adapter-skin-2="ui-card-skin-#1"
        data-uia-card-skin="1"
        data-uia-card-mod="1"
      >
        <div className="uia-timeline__container">
          <div className="uia-timeline__line"></div>
          <div className="uia-timeline__groups">
            {data ? (
              data.map((item: any, index: any) => (
                <div key={index} className="uia-timeline__group">
                  <div
                    className="uia-timeline__dot"
                    style={{ cursor: "pointer" }}
                  ></div>
                  <div
                    className="uia-timeline__label"
                    style={{ cursor: "pointer", fontWeight: "bold" }}
                  >
                    <h5
                      id={`experience-step-${item.date}-heading`}
                      className="ra-heading"
                    >
                      {item.date}
                    </h5>
                  </div>

                  <div className="uia-timeline__content">
                    <div className="uia-card">
                      <div className="uia-card__container">
                        <div className="uia-card__body">
                          <div className="profile-card-inf">
                            <div className="profile-card-inf__item">
                              <div className="profile-card-inf__title">
                                {item.status}
                              </div>
                              <div className="profile-card-inf__txt">
                                {item.payment && payments[item.payment]?.[0]
                                  ? `${payments[item.payment][0].type} : ${
                                      payments[item.payment][0].cout
                                    } TND`
                                  : null}
                              </div>
                            </div>
                            {/* <div className="profile-card-inf__item">
                                <div className="profile-card-inf__title">
                                  {value.count_bls}
                                </div>
                                <div className="profile-card-inf__txt">
                                  Nombre de BLS
                                </div>
                              </div>
                              <div className="profile-card-inf__item">
                                <div className="profile-card-inf__title">
                                  {value.nbr_visite}
                                </div>
                                <div className="profile-card-inf__txt">
                                  Visites
                                </div>
                              </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>No timeline data available.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VehicleTimelineHistory;
