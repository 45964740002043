import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";

import { Button, Modal } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";
import { PieceRechangeType } from "../../_App/Types/Entites/PieceRechangeType";
import {
  ListPiecesDeRechange,
  savePieceDeRechange,
  updatePieceDeRechange,
} from "../../_App/Redux/Slices/piecesRechange/piecesRechangeSlice";
import { EntretienType } from "../../_App/Types/Entites/EntretienType";
import {
  entretienPieces,
  selectEntretiens,
} from "../../_App/Redux/Slices/entretiens/entretienSlice";
import { generateCode } from "../../_App/Helpers/helpers";
import {
  currentSousSociete,
  OnlineUser,
} from "../../_App/Redux/Slices/auth/authSlice";
import { toast } from "react-toastify";
import { files, saveFile } from "../../_App/Redux/Slices/files/filesSlice";

const PieceDeRechangeForm = ({
  selectedPiece,
  showModal,
  setShowModal,
  setSelectedPiece,
  load,
  setLoad,
}: any) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const entretiens = useAppSelector(selectEntretiens);
  const pieces = useAppSelector(ListPiecesDeRechange);
  const currentSousSte = useAppSelector(currentSousSociete);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<PieceRechangeType>();

  useEffect(() => {
    if (selectedPiece) {
      reset(selectedPiece);
    } else {
      reset({
        code: generateCode(pieces, "P"),
        libelle: "",
        prix_HT: 0,
        prix_TTC: 0,
        tva: 0,
        remise: 0,
        fournisseur: "",
        sous_societe: currentSousSte,
        stock: 0,
      });
    }
  }, [selectedPiece, showModal]);

  const closeModal = () => {
    if (setSelectedPiece !== null) setSelectedPiece(undefined);
    setShowModal(false);
  };

  const [file, setFile] = useState<File | null>(null);
  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };
  const uploadFile = async () => {
    if (!file) return "";

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}files/upload`,
        {
          method: "POST",
          body: formData,
        }
      );
      const data = await response.json();
      if (data.success) {
        console.log("================>", data);
        return data.fileName;
      } else {
        toast.error("File upload failed");
        return "";
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      toast.error("Error uploading file");
      return "";
    }
  };
  const docs = useAppSelector(files);

  const onSubmit: SubmitHandler<PieceRechangeType> = async (data) => {
    if (selectedPiece) {
      try {
        dispatch(updatePieceDeRechange({ id: selectedPiece.id, data: data }));
        setLoad(!load);
        closeModal();
      } catch (error) {
        console.error("Error updating piece de rechange:", error);
      }
    } else {
      let res = await dispatch(savePieceDeRechange(data));
      if (res.payload.success) {
        const fileName = await uploadFile(); // Await file upload

        let fileData = {
          code: res.payload.data.code,
          type: "piece_docs",
          path: fileName, // Use the full URL path returned by uploadFile
          // path: process.env.REACT_APP_API_URL_UPLOADS + fileName,
        };
        dispatch(saveFile(fileData));
        setLoad(!load);
        closeModal();
      }
    }
  };

  return (
    <Modal show={showModal} onHide={closeModal}>
      <Modal.Header>
        <Modal.Title>
          <span className="modal-title">
            {selectedPiece
              ? t("Mise à jour de pièce de rechange") + selectedPiece.code
              : t("Ajouter une pièce de rechange")}
          </span>
        </Modal.Title>
        <button
          type="reset"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={(e) => {
            if (setSelectedPiece !== null) setSelectedPiece(undefined);
            setShowModal(false);
          }}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)}>
          {!selectedPiece ? (
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <label htmlFor="file">{t("Fichier")}</label>
                  <input
                    type="file"
                    className="form-control"
                    onChange={onFileChange}
                  />
                </div>
              </div>
            </div>
          ) : null}
          {selectedPiece &&
            docs &&
            docs[selectedPiece.code] &&
            docs[selectedPiece.code][0] && (
              <div className="row mb-3">
                <div className="col-12">
                  <a
                    href={
                      process.env.REACT_APP_API_URL_UPLOADS +
                      docs[selectedPiece.code][0].path
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t("Télécharger le fichier associé")}
                  </a>
                </div>
              </div>
            )}
          <div className="row">
            {" "}
            <div className="col-12">
              <div className="form-group">
                <label htmlFor="libelle">
                  {t("Libellé")} <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  {...register("libelle")}
                />
              </div>
            </div>
          </div>
          <div className="row">
            {" "}
            <div className="col-6">
              <div className="form-group">
                <label htmlFor="fournisseur">
                  {t("Fournisseur")} <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  {...register("fournisseur")}
                />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <label htmlFor="stock">
                  {t("Stock")} <span className="text-danger">*</span>
                </label>
                <input
                  type="number"
                  className="form-control"
                  {...register("stock")}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <label htmlFor="prix">
                  {t("Prix HT")} <span className="text-danger">*</span>
                </label>
                <input
                  type="number"
                  className="form-control"
                  {...register("prix_HT")}
                />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <label htmlFor="prix">
                  {t("Prix TTC")} <span className="text-danger">*</span>
                </label>
                <input
                  type="number"
                  className="form-control"
                  {...register("prix_TTC")}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <label htmlFor="fournisseur">
                  {t("TVA")} <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  {...register("tva")}
                />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <label htmlFor="prix">
                  {t("remise")} <span className="text-danger">*</span>
                </label>
                <input
                  type="number"
                  className="form-control"
                  {...register("remise")}
                />
              </div>
            </div>
          </div>

          <Button variant="primary" type="submit" className="mr-2 float-right">
            {t("Enregistrer")}
          </Button>
          <Button
            variant="secondary"
            className="mr-2 float-right"
            onClick={() => closeModal()}
          >
            {t("Annuler")}
          </Button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default PieceDeRechangeForm;
