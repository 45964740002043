import moment from "moment";
import React, { useState } from "react";
import { duration, numberWithSpaces } from "../../_App/Helpers/helpers";
import { useAppSelector } from "../../_App/Redux/hooks";

import _ from "lodash";
import { MonthAwayAssuranceByVehicule } from "../../_App/Redux/Slices/assurances/assurancesSlice";
import { AssuranceType } from "../../_App/Types/Entites/AssuranceType";
import {
  ListVehiculesParCode,
  ListVehiculesParStatus,
  ListVehiculesRepairsTimes,
  TodayCharge,
} from "../../_App/Redux/Slices/vehicules/vehiculesSlice";
import { ListMonthAwayVisiteTech } from "../../_App/Redux/Slices/visiteTech/visiteTechSlice";
import { useTranslation } from "react-i18next";
import { ListMonthAwaytaxes } from "../../_App/Redux/Slices/taxes/taxesSlice";
import PayAssuranceForm from "../PayHistory/PayForm";

const DashboardCard = ({ today, entretienCost, load, setLoad }: any) => {
  const month = new Date(today).getMonth() + 1;
  const { t } = useTranslation();
  const todayCharge = useAppSelector(TodayCharge);
  const oneMonthAwayAssurance = useAppSelector(MonthAwayAssuranceByVehicule);

  // const [details, setDetails] = useState<any>();

  const details = (): any => {
    const gr = _.groupBy(todayCharge?.net_a_payer, "month");
    const month = Object.keys(gr)[0]; // Assuming you want to get the month dynamically
    const ca_mensuel = gr[month]?.reduce(
      (totalSum: any, { net_a_payer }: any) =>
        totalSum + parseFloat(net_a_payer),
      0
    );
    const ca_annuel = todayCharge?.net_a_payer.reduce(
      (totalSum: any, { net_a_payer }: any) =>
        totalSum + parseFloat(net_a_payer),
      0
    );
    return {
      chargesfixes: todayCharge?.chargesFixes?.charges_fixes,
      chargesvariables: todayCharge?.chargesvariables?.charges_variables,
      camensuel: ca_mensuel,
      caannuel: ca_annuel,
      km_m: todayCharge?.km_mensuel?.km_mensuel,
      km_a: todayCharge?.km_annuel?.km_annuel,
    };
  };
  const vehicules = useAppSelector(ListVehiculesParCode);
  const vehiculesRepairsTimes = useAppSelector(ListVehiculesRepairsTimes);
  const monthAwayVisite = useAppSelector(ListMonthAwayVisiteTech);
  const monthAwayTaxes = useAppSelector(ListMonthAwaytaxes);

  const vehiculeByStatus = useAppSelector(ListVehiculesParStatus);

  const [showModalPay, setShowModalPay] = useState(false);
  const [selectedToPay, setSelectedToPay] = useState<AssuranceType | undefined>(
    undefined
  );
  const [selectTypeToPay, setSelectedTypeToPay] = useState<string | undefined>(
    undefined
  );
  const totalAssurance = Object.values(oneMonthAwayAssurance).reduce(
    (sum: number, item: any) => sum + item[0].montant_TTC,
    0
  );
  const totalVisite = Object.values(monthAwayVisite).reduce(
    (sum: number, item: any) => sum + item[0].montant_TTC,
    0
  );
  const totalTaxes = Object.values(monthAwayTaxes).reduce(
    (sum: number, item: any) => sum + item[0].montant_TTC,
    0
  );
  const totalEntretiens = vehiculesRepairsTimes.reduce(
    (sum: number, item: any) => sum + item.total_cost,
    0
  );

  return (
    <>
      <PayAssuranceForm
        showModal={showModalPay}
        setShowModal={setShowModalPay}
        toPay={selectedToPay}
        type={selectTypeToPay}
        load={load}
        setLoad={setLoad}
        setType={setSelectedTypeToPay}
        setToPay={setSelectedToPay}
      />{" "}
      <div className="col-12 col-xl-12 stretch-card">
        <div className="col-md-6">
          {/* <div className="col-12 mb-3">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-baseline">
                  <h6 className="mb-0 text-primary">
                    {t("Cout des entretiens cet mois")}
                  </h6>
                </div>
                <div className="row">
                  <div className="col-6 col-xl-5">
                    <h5 className=" mt-3">{entretienCost} (TND)</h5>
                  </div>
                  <div className="col-6 col-xl-7 ">
                    <div
                      className="circle-icon float-right"
                      style={{
                        height: 50,
                        width: 50,
                        lineHeight: 0,
                        fontSize: 20,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <i className="fas fa-hand-holding-usd text-primary"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div className="col-12 mb-3">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-baseline">
                  <h6 className="mb-0 text-primary">
                    {t("Renouvellement d'assurances dans un mois")}
                  </h6>
                </div>
                <div className="table-responsive ">
                  <table className="table table-hover  mb-0">
                    <thead>
                      <tr className=" fs-10 ">
                        <th>{t("Vehicule")}</th>
                        <th>{t("Date d'expiration")}</th>
                        <th>{t("Coût")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.values(oneMonthAwayAssurance)?.map(
                        (items: any, keys: any) => (
                          <tr
                            key={keys}
                            className={
                              keys % 2 === 1
                                ? "fs-12 bg-azure-lightest "
                                : "fs-12 "
                            }
                            onClick={() => {
                              setSelectedToPay(items[0]);
                              setSelectedTypeToPay("assurance");
                              setShowModalPay(true);
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <td>
                              {Object.keys(vehicules).includes(
                                items[0].vehicule_code
                              ) &&
                              vehicules[items[0].vehicule_code][0]
                                .code_depot ? (
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/images/nomadis.png"
                                  }
                                  alt="nomadis"
                                  style={{ borderRadius: 0 }}
                                />
                              ) : null}
                              {vehicules[items[0].vehicule_code] &&
                                vehicules[items[0].vehicule_code][0].marque +
                                  " " +
                                  vehicules[items[0].vehicule_code][0]
                                    .num_serie}
                            </td>
                            <td className="text-danger">
                              <b>{items[0].date_fin}</b>
                            </td>
                            <td className="text-danger">
                              <b>{items[0].montant_TTC}</b>
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                    <tfoot>
                      <tr className={"fs-12 "}>
                        <td colSpan={2}>
                          <b>{t("Coût total")}</b>
                        </td>
                        <td className="text-danger">
                          <b> {totalAssurance} </b>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
              <div className="card-footer">
                <p className="text-muted">
                  <span
                    className="text-primary"
                    style={{
                      float: "right",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => (window.location.href = "/assurances")}
                  >
                    {" "}
                    <b className="text-primary">{t("Voir assurances")}</b>{" "}
                    <i className="fa fas fa-arrow-right text-primary"></i>{" "}
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 mb-3">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-baseline">
                  <h6 className="mb-0 text-primary">
                    {t("Renouvellement de visites techniques dans un mois")}
                  </h6>
                </div>
                <div className="table-responsive ">
                  <table className="table table-hover  mb-0">
                    <thead>
                      <tr className=" fs-10 ">
                        <th>{t("Vehicule")}</th>
                        <th>{t("Date expiration")}</th>
                        <th>{t("Coût")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.values(monthAwayVisite)?.map(
                        (items: any, keys: any) => (
                          <tr
                            key={keys}
                            className={
                              keys % 2 === 1
                                ? "fs-12 bg-azure-lightest "
                                : "fs-12 "
                            }
                            onClick={() => {
                              setSelectedToPay(items[0]);
                              setSelectedTypeToPay("visite");
                              setShowModalPay(true);
                            }}
                          >
                            <td>
                              {Object.keys(vehicules).includes(
                                items[0].vehicule_code
                              ) &&
                              vehicules[items[0].vehicule_code][0]
                                .code_depot ? (
                                <>
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/assets/images/nomadis.png"
                                    }
                                    alt="nomadis"
                                    style={{ borderRadius: 0 }}
                                  />
                                </>
                              ) : null}
                              {vehicules[items[0].vehicule_code] &&
                                vehicules[items[0].vehicule_code][0].marque +
                                  " " +
                                  vehicules[items[0].vehicule_code][0]
                                    .num_serie}
                            </td>
                            <td className="text-danger">
                              <b>{items[0].date_fin}</b>
                            </td>
                            <td className="text-danger">
                              <b>{items[0].montant_TTC}</b>
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                    <tfoot>
                      <tr className={"fs-12 "}>
                        <td colSpan={2}>
                          <b>{t("Coût total")}</b>
                        </td>
                        <td className="text-danger">
                          <b> {totalVisite} </b>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
              <div className="card-footer">
                <p className="text-muted">
                  <span
                    className="text-primary"
                    style={{
                      float: "right",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => (window.location.href = "/visitesTech")}
                  >
                    {" "}
                    <b className="text-primary">
                      {t("Voir visites techniques")}
                    </b>{" "}
                    <i className="fa fas fa-arrow-right text-primary"></i>{" "}
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 mb-3">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-baseline">
                  <h6 className="mb-0 text-primary">
                    {t("Taxes dans un mois")}
                  </h6>
                </div>
                <div className="table-responsive ">
                  <table className="table table-hover  mb-0">
                    <thead>
                      <tr className=" fs-10 ">
                        <th>{t("Vehicule")}</th>
                        <th>{t("Date expiration")}</th>
                        <th>{t("Coût")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.values(monthAwayTaxes)?.map(
                        (items: any, keys: any) => (
                          <tr
                            key={keys}
                            className={
                              keys % 2 === 1
                                ? "fs-12 bg-azure-lightest "
                                : "fs-12 "
                            }
                            onClick={() => {
                              setSelectedToPay(items[0]);
                              setSelectedTypeToPay("taxe");
                              setShowModalPay(true);
                            }}
                          >
                            <td>
                              {Object.keys(vehicules).includes(
                                items[0].vehicule_code
                              ) &&
                              vehicules[items[0].vehicule_code][0]
                                .code_depot ? (
                                <>
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/assets/images/nomadis.png"
                                    }
                                    alt="nomadis"
                                    style={{ borderRadius: 0 }}
                                  />
                                </>
                              ) : null}
                              {vehicules[items[0].vehicule_code] &&
                                vehicules[items[0].vehicule_code][0].marque +
                                  " " +
                                  vehicules[items[0].vehicule_code][0]
                                    .num_serie}
                            </td>
                            <td className="text-danger">
                              <b>{items[0].date_fin}</b>
                            </td>
                            <td className="text-danger">
                              <b>{items[0].montant_TTC}</b>
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                    <tfoot>
                      <tr className={"fs-12 "}>
                        <td colSpan={2}>
                          <b>{t("Coût total")}</b>
                        </td>
                        <td className="text-danger">
                          <b> {totalTaxes} </b>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
              <div className="card-footer">
                <p className="text-muted">
                  <span
                    className="text-primary"
                    style={{
                      float: "right",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => (window.location.href = "/taxes")}
                  >
                    {" "}
                    <b className="text-primary">{t("Voir taxes")}</b>{" "}
                    <i className="fa fas fa-arrow-right text-primary"></i>{" "}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="row" style={{ padding: "0px 12px" }}>
            <div className="col-6 mb-3">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-baseline">
                    <h6
                      className="mb-0 text-primary"
                      onClick={() => console.log(vehiculeByStatus["en panne"])}
                    >
                      {t("Vehicules en pannes")}
                      {/* {(vehiculeByStatus["en panne"] &&
                        vehiculeByStatus["en panne"]?.length /
                          Object.values(vehicules)?.length) *
                        100 >
                      50 ? (
                        <b className="text-danger">
                          {vehiculeByStatus["en panne"] &&
                            "(" +
                              Math.floor(
                                (vehiculeByStatus["en panne"]?.length /
                                  Object.values(vehicules)?.length) *
                                  100
                              ) +
                              "%)"}
                        </b>
                      ) : (
                        (
                          <b>
                            {vehiculeByStatus["en panne"] &&
                              "(" +
                                Math.floor(
                                  (vehiculeByStatus["en panne"]?.length /
                                    Object.values(vehicules)?.length) *
                                    100
                                ) +
                                "%)"}
                          </b>
                        ) + ")"
                      )} */}
                    </h6>
                  </div>
                  <div className="row mt-2">
                    <div className="col-6 col-md-12 col-xl-6">
                      <h5 className=" mt-3">
                        {vehiculeByStatus["en panne"]?.length + " vehicules"}
                      </h5>
                    </div>
                    <div className="col-6 col-md-12 col-xl-6">
                      <div
                        className="circle-icon float-right"
                        style={{
                          height: 40,
                          width: 40,
                          lineHeight: 0,
                          fontSize: 20,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: "#e60039",
                        }}
                      >
                        <i className="fas fa-truck text-white"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 mb-3">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-baseline">
                    <h6 className="mb-0 text-primary">
                      {t("Vehicules en réparation ")}
                      {/* {(vehiculeByStatus["en reparation"] &&
                        vehiculeByStatus["en reparation"]?.length /
                          Object.values(vehicules)?.length) *
                        100 >
                      50 ? (
                        <b className="text-danger">
                          {vehiculeByStatus["en reparation"] &&
                            "(" +
                              Math.floor(
                                (vehiculeByStatus["en reparation"]?.length /
                                  Object.values(vehicules)?.length) *
                                  100
                              ) +
                              "%)"}{" "}
                        </b>
                      ) : (
                        <b>
                          {vehiculeByStatus["en reparation"] &&
                            "(" +
                              Math.floor(
                                (vehiculeByStatus["en reparation"]?.length /
                                  Object.values(vehicules)?.length) *
                                  100
                              ) +
                              "%)"}{" "}
                        </b>
                      )} */}
                    </h6>
                  </div>
                  <div className="row mt-2">
                    <div className="col-6 col-md-12 col-xl-6 ">
                      <h5 className=" mt-3">
                        {(vehiculeByStatus["en reparation"]
                          ? vehiculeByStatus["en reparation"]?.length
                          : 0) + " vehicules"}
                      </h5>
                    </div>
                    <div className="col-6 col-md-12 col-xl-6 ">
                      <div
                        className="circle-icon float-right"
                        style={{
                          height: 40,
                          width: 40,
                          lineHeight: 0,
                          fontSize: 20,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          background: "#fbbc06",
                        }}
                      >
                        <i className="fas fa-truck text-white"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 mb-3">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-baseline">
                  <h6 className="mb-0 text-primary">
                    {t("Les véhicules les plus réparés")}
                  </h6>
                </div>
                <div className="table-responsive ">
                  <table className="table table-hover  mb-0">
                    <thead>
                      <tr className=" fs-10 ">
                        <th>{t("Vehicule")}</th>
                        <th>{t("Entretiens")}</th>
                        <th>{t("Coûts")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {vehiculesRepairsTimes.map((items: any, keys: any) => (
                        <tr
                          key={keys}
                          className={
                            keys % 2 === 1
                              ? "fs-12 bg-azure-lightest "
                              : "fs-12 "
                          }
                        >
                          <td>
                            {Object.keys(vehicules).includes(items.code) &&
                            vehicules[items.code][0].code_depot !== "null" ? (
                              <>
                                <img
                                onClick={()=> console.log(vehicules[items.code][0])}
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/images/nomadis.png"
                                  }
                                  alt="nomadis"
                                  style={{ borderRadius: 0 }}
                                />
                              </>
                            ) : null}
                            {vehicules[items.code] &&
                              vehicules[items.code][0].marque +
                                " " +
                                vehicules[items.code][0].num_serie}
                          </td>
                          <td className="text-primary">
                            <b>{items.total_repairs_count}</b>
                          </td>
                          <td className="text-primary">
                            <b>{items.total_cost | 0}</b>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    <tfoot>
                      <tr className={"fs-12 "}>
                        <td colSpan={2}>
                          <b>{t("Coût total")}</b>
                        </td>
                        <td className="text-danger">
                          <b> {totalEntretiens} </b>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
              <div className="card-footer">
                <p className="text-muted">
                  <span
                    className="text-primary"
                    style={{
                      float: "right",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => (window.location.href = "/vehicules")}
                  >
                    {" "}
                    <b className="text-primary">{t("Voir vehicules")}</b>{" "}
                    <i className="fa fas fa-arrow-right text-primary"></i>{" "}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/** ------------------------- LIGNE 2 CARDS ---------------------------------------------- */}
      <div className="col-12 col-xl-12 stretch-card">
        <div className="col-6"></div>
        <div className="col-6"></div>
      </div>
    </>
  );
};

export default DashboardCard;
