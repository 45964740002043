import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import _ from "lodash";
import { VisiteTechType } from "../../../Types/Entites/VisiteTechType";
import { RootState } from "../../store";

export const saveFile = createAsyncThunk(
  "files/saveFile",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await axios.post("files/saveFile", data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

export const uploadFile = createAsyncThunk(
  "files/upload",
  async ({formData} : any, { rejectWithValue }) => {
    try {
      const response = await axios.post("files/upload", formData);
      return response.data; // Adjust according to your API response structure
    } catch (error) {
      return rejectWithValue({
        success: false,
      });
    }
  }
);
export const findFiles = createAsyncThunk(
  "files/findFiles",
  async (data: null, { rejectWithValue }) => {
    try {
      const response = await axios.get("files/findFiles");
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);
interface Files {
  filesByCode: { [code: string]: any[] };
}

const initialState: Files = {
  filesByCode: {},
};

export const FileSlice = createSlice({
  name: "files",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(findFiles.fulfilled, (state, action) => {
      const groupedByCode = _.groupBy(action.payload.data, "code");

      return {
        ...state,
        filesByCode: groupedByCode,
      };
    });
  },
});
export const files = (state: RootState) => state.files.filesByCode;
export default FileSlice.reducer;
