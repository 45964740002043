import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { SubmitHandler, useForm } from "react-hook-form";
import { Button, Modal } from "react-bootstrap";
import moment from "moment";
import { ListVehicules } from "../../_App/Redux/Slices/vehicules/vehiculesSlice";
import {
  saveAssurance,
  updateAssurance,
} from "../../_App/Redux/Slices/assurances/assurancesSlice";
import { toast } from "react-toastify";
import { AssuranceType } from "../../_App/Types/Entites/AssuranceType";
import {
  currentSousSociete,
  OnlineUser,
} from "../../_App/Redux/Slices/auth/authSlice";
import { files, saveFile } from "../../_App/Redux/Slices/files/filesSlice";

const AssuranceForm = ({
  selectedAssurance,
  showModal,
  setShowModal,
  setSelectedAssurance,
  load,
  setLoad,
}: any) => {
  const { t } = useTranslation();
  const vehicules = useAppSelector(ListVehicules); // Assuming you have a slice for vehicules in Redux
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const currentSousSte = useAppSelector(currentSousSociete);
  const docs = useAppSelector(files);

  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<AssuranceType>();

  useEffect(() => {
    if (selectedAssurance) {
      reset(selectedAssurance);
    } else {
      reset({
        vehicule_code: "",
        date: "",
        date_fin: "",
        num_contrat: "",
        montant_TTC: 0,
        montant_HT: 0,
        echeance: "",
        categorie: "",
        piece_jointe: "",
        sous_societe: currentSousSte,
      });
    }
  }, [selectedAssurance, showModal]);

  const closeModal = () => {
    if (setSelectedAssurance !== null) setSelectedAssurance(undefined);
    setShowModal(false);
  };
  const [file, setFile] = useState<File | null>(null);
  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };
  const uploadFile = async () => {
    if (!file) return "";

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}files/upload`,
        {
          method: "POST",
          body: formData,
        }
      );
      const data = await response.json();
      if (data.success) {
        console.log("================>", data);
        return data.fileName;
      } else {
        toast.error("File upload failed");
        return "";
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      toast.error("Error uploading file");
      return "";
    }
  };

  const onSubmit: SubmitHandler<AssuranceType> = async (data) => {
    if (selectedAssurance) {
      // let newData = { ...data };
      // newData.date_mise_en_circulation = date;
      try {
        dispatch(updateAssurance({ id: selectedAssurance.id, data: data }));
        setLoad(!load);
        closeModal();
      } catch (error) {
        console.error("Error updating assurance:", error);
      }
    } else {
      let res = await dispatch(saveAssurance(data));
      if (res.payload.success) {
        const fileName = await uploadFile(); // Await file upload

        let fileData = {
          code: res.payload.data.id,
          type: "assurance_docs",
          path: fileName, // Use the full URL path returned by uploadFile
          // path: process.env.REACT_APP_API_URL_UPLOADS + fileName,
        };
        dispatch(saveFile(fileData));
        setLoad(!load);
        closeModal();
        toast.success("Vehicule ajoutée avec succes ! ");
      }
    }
  };

  return (
    <Modal show={showModal} onHide={closeModal}>
      <Modal.Header>
        <Modal.Title>
          <span className="modal-title" id="addAssuranceLabel">
            <i className="mdi mdi-plus-circle text-primary mr-1" />
            {selectedAssurance ? t("MAJ Assurance") : t("Nouvelle Assurance")}
          </span>
        </Modal.Title>
        <button
          type="reset"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={(e) => {
            if (setSelectedAssurance !== null) setSelectedAssurance(undefined);
            setShowModal(false);
          }}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className="card-body">
          <form onSubmit={handleSubmit(onSubmit)}>
            {!selectedAssurance ? (
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <label htmlFor="file">{t("Fichier")}</label>
                    <input
                      type="file"
                      className="form-control"
                      onChange={onFileChange}
                    />
                  </div>
                </div>
              </div>
            ) : null}
            {selectedAssurance &&
              docs &&
              docs[selectedAssurance.id] &&
              docs[selectedAssurance.id][0] && (
                <div className="row mb-3">
                  <div className="col-12">
                    <a
                      href={
                        process.env.REACT_APP_API_URL_UPLOADS +
                        docs[selectedAssurance.id][0].path
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t("Télécharger le fichier associé")}
                    </a>
                  </div>
                </div>
              )}
            <div className="row">
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="vehicule_code">
                    {t("Vehicule")}
                    <span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-control"
                    {...register("vehicule_code")}
                  >
                    {vehicules.map((vehicule: any) => (
                      <option key={vehicule.code} value={vehicule.code}>
                        {vehicule.num_serie}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="date">
                    {t("Date")}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    {...register("date")}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="date_fin">
                    {t("Date Fin")}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    {...register("date_fin")}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="num_contrat">
                    {t("Num Contrat")}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    {...register("num_contrat")}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="montant_TTC">
                    {t("Montant TTC")}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    {...register("montant_TTC")}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="montant_HT">
                    {t("Montant HT")}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    {...register("montant_HT")}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="echeance">
                    {t("Echeance")}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    {...register("echeance")}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="categorie">
                    {t("Categorie")}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    {...register("categorie")}
                  />
                </div>
              </div>
            </div>

            <Button
              variant="primary"
              type="submit"
              className="mr-2 float-right"
            >
              {t("Enregistrer")}
            </Button>
            <Button
              variant="secondary"
              className="mr-2 float-right"
              onClick={() => closeModal()}
            >
              {t("Annuler")}
            </Button>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AssuranceForm;
