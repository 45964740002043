import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import axios from "axios";
import { CamionType } from "../../../Types/Entites/CamionsTypes";
import _ from "lodash";
import {
  AffectationUserVehiculeType,
  VehiculeType,
} from "../../../Types/Entites/VehiculeType";
import moment from "moment";

export const saveVehicule = createAsyncThunk(
  "vehicules/createVehicule",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await axios.post("vehicules/createVehicule", data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);
// export const saveFile = createAsyncThunk(
//   "vehicules/saveFile",
//   async (data: any, { rejectWithValue }) => {
//     try {
//       const response = await axios.post("vehicules/saveFile", data);
//       return response.data;
//     } catch (error: any) {
//       return rejectWithValue({
//         success: false,
//         message: error.response.data.message,
//         action: error.response.data.action,
//         data: error.response.data.data,
//       });
//     }
//   }
// );

export const saveHistorique = createAsyncThunk(
  "vehicules/createHistorique",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await axios.post("vehicules/createHistorique", data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

export const findVehicules = createAsyncThunk(
  "vehicules/findVehicules",
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`vehicules/findVehicules/${id}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
export const findhistoriqueVehicule = createAsyncThunk(
  "vehicules/findHistorique",
  async (id: null, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`vehicules/findHistorique`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const findTop10Camions = createAsyncThunk(
  "chargesCamion/top10",
  async (id: null, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`chargesCamion/top10`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
export const findVehiculesAffectations = createAsyncThunk(
  "affectationUserVehicule/findAffectations",
  async (id: null, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `affectationUserVehicule/findAffectations`
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const findVehiculesAffectationsHistory = createAsyncThunk(
  "affectationUserVehicule/findVehiculeHistory",
  async (id: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `affectationUserVehicule/findVehiculeHistory/${id}`
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const findChargeCamionsToday = createAsyncThunk(
  "chargesCamion/all/:today",
  async (today: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`chargesCamion/all/${today}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const findCamions = createAsyncThunk(
  "vehicules/findCamions",
  async (id: null, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`vehicules/findCamions`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
export const findDepots = createAsyncThunk(
  "vehicules/findDepots",
  async (id: null, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`vehicules/findDepots`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
export const updateVehicule = createAsyncThunk(
  "vehicules/updateVehicule",
  async ({ id, data }: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.put(`vehicules/updateVehicule/${id}`, data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
export const deleteVehicule = createAsyncThunk(
  "vehicules/deleteVehicule",
  async (id: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.delete(`vehicules/deleteVehicule/${id}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const findVehiculesNumberOfRepairs = createAsyncThunk(
  "vehicules/numberVehiculeRepairs",
  async (id: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`vehicules/numberVehiculeRepairs/${id}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const affectVehiculeToUser = createAsyncThunk(
  "affectationUserVehicule/createAffectation",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        "affectationUserVehicule/createAffectation",
        data
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

export const findZones = createAsyncThunk(
  "affectationUserVehicule/findZones",
  async (data: null, { rejectWithValue }) => {
    try {
      const response = await axios.get("affectationUserVehicule/findZones");
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);
export const FindVehiculesInAndOut = createAsyncThunk(
  "vehicules/vehiculesInAndOut",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await axios.get(`vehicules/vehiculesInAndOut/${id}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

interface Vehicules {
  vehiculesList: VehiculeType[];
  vehiculeHistory: AffectationUserVehiculeType[];
  camionsByCodes: { [code: string]: CamionType[] };
  vehiculesByCode: { [code: string]: VehiculeType[] };
  vehiculesByStatus: { [status: string]: VehiculeType[] };

  affectationByVehic: { [code: string]: AffectationUserVehiculeType[] };
  top10Camions: any;
  todayCharge: any;
  VehiculesRepairsTimes: any;
  zones: any;
  zonesByCode: { [code: string]: any };
  historique: any;
  historiqueByVehicule: any;
  vehiculesInAndOut: any;
  depots: CamionType[];
}

const initialState: Vehicules = {
  vehiculesList: [],
  vehiculeHistory: [],
  camionsByCodes: {},
  affectationByVehic: {},
  top10Camions: [],
  todayCharge: null,
  vehiculesByCode: {},
  VehiculesRepairsTimes: [],
  vehiculesByStatus: {},
  zones: [],
  zonesByCode: {},
  historique: {},
  historiqueByVehicule: {},
  vehiculesInAndOut: {},
  depots: [],
};

export const vehiculeSlice = createSlice({
  name: "camions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(saveVehicule.fulfilled, (state, action) => {
      let newCamion = action.payload.data;
      state.vehiculesList.splice(0, 0, newCamion);
    });
    builder.addCase(findVehicules.fulfilled, (state, action) => {
      const groupedByCode = _.groupBy(action.payload.data, "code");
      const groupedByStatus = _.groupBy(action.payload.data, "status");

      return {
        ...state,
        vehiculesList: action.payload.data,
        vehiculesByCode: groupedByCode,
        vehiculesByStatus: groupedByStatus,
      };
    });
    builder.addCase(
      findVehiculesAffectationsHistory.fulfilled,
      (state, action) => {
        return {
          ...state,
          vehiculeHistory: action.payload.data,
        };
      }
    );
    builder.addCase(findhistoriqueVehicule.fulfilled, (state, action) => {
      const filteredData = _.filter(
        action.payload.data,
        (item) => moment(item.date).year() === moment().year()
      );

      // Group by month
      const groupedByMonth = _.groupBy(filteredData, (item) =>
        moment(item.date).format("MM")
      );
      const groupedByMonthAndStatus = _.mapValues(groupedByMonth, (items) =>
        _.groupBy(items, "status")
      );
      const groupedByVehicule = _.groupBy(action.payload.data, "vehicule_code");

      return {
        ...state,
        historique: groupedByMonthAndStatus,
        historiqueByVehicule: groupedByVehicule,
      };
    });
    builder.addCase(findChargeCamionsToday.fulfilled, (state, action) => {
      return {
        ...state,
        todayCharge: action.payload,
      };
    });
    builder.addCase(findDepots.fulfilled, (state, action) => {
      return {
        ...state,
        depots: action.payload.data,
      };
    });
    builder.addCase(FindVehiculesInAndOut.fulfilled, (state, action) => {
      return {
        ...state,
        vehiculesInAndOut: action.payload.data,
      };
    });
    builder.addCase(findTop10Camions.fulfilled, (state, action) => {
      return {
        ...state,
        top10Camions: action.payload.topcamions,
      };
    });
    builder.addCase(findVehiculesNumberOfRepairs.fulfilled, (state, action) => {
      return {
        ...state,
        VehiculesRepairsTimes: action.payload.data,
      };
    });
    builder.addCase(findCamions.fulfilled, (state, action) => {
      const groupedByCode = _.groupBy(action.payload.data, "code");
      return {
        ...state,
        camionsByCodes: groupedByCode,
      };
    });
    builder.addCase(findVehiculesAffectations.fulfilled, (state, action) => {
      const groupedByVehiucle = _.groupBy(action.payload.data, "vehicule_code");
      return {
        ...state,
        affectationByVehic: groupedByVehiucle,
      };
    });
    builder.addCase(updateVehicule.fulfilled, (state, action) => {
      const index = _.findIndex(state.vehiculesList, {
        id: action.payload.data.id,
      });
      if (index !== -1) {
        const updatedList = [...state.vehiculesList];
        updatedList[index] = {
          ...updatedList[index],
        };
        return {
          ...state,
          vehiculesList: updatedList,
        };
      }

      return state;
    });

    builder.addCase(findZones.fulfilled, (state, action) => {
      const groupedByCode = _.groupBy(action.payload.data, "code");

      return {
        ...state,
        zones: action.payload.data,
        zonesByCode: groupedByCode,
      };
    });
  },
});

export const ListVehicules = (state: RootState) =>
  state.vehicules.vehiculesList;
export const HistoriqueVehicules = (state: RootState) =>
  state.vehicules.historique;
export const HistoriqueVehiculesParVehicule = (state: RootState) =>
  state.vehicules.historiqueByVehicule;
export const ListVehiculesParCode = (state: RootState) =>
  state.vehicules.vehiculesByCode;
export const ListVehiculesParStatus = (state: RootState) =>
  state.vehicules.vehiculesByStatus;
export const ListCamions = (state: RootState) => state.vehicules.camionsByCodes;
export const Top10Camions = (state: RootState) => state.vehicules.top10Camions;
export const TodayCharge = (state: RootState) => state.vehicules.todayCharge;

export const ListAffectations = (state: RootState) =>
  state.vehicules.affectationByVehic;
export const VehiculeHistory = (state: RootState) =>
  state.vehicules.vehiculeHistory;
export const ListVehiculesRepairsTimes = (state: RootState) =>
  state.vehicules.VehiculesRepairsTimes;
export const ListZones = (state: RootState) => state.vehicules.zones;
export const ListZonesByCode = (state: RootState) =>
  state.vehicules.zonesByCode;
export const ListVehiculesInAndOut = (state: RootState) =>
  state.vehicules.vehiculesInAndOut;
export const ListDepots = (state: RootState) =>
  state.vehicules.depots;

export default vehiculeSlice.reducer;
