import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import axios from "axios";
import {
  UserSousSocietesType,
  UserType,
} from "../../../Types/Entites/UserType";
import _ from "lodash";

export const getStats = createAsyncThunk(
  "stats/soussocietecouts",
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `stats/soussocietecouts/${data.start}/${data.end}`
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const getVehiculesStats = createAsyncThunk(
  "stats/vehiculecouts",
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `stats/vehiculecouts/${data.start}/${data.end}/${data.id}`
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

interface stats {
  stats: any[];
  statsByVehicules: { [vehicule: string]: any };
}

const initialState: stats = {
  stats: [],
  statsByVehicules: {},
};

export const statsSlice = createSlice({
  name: "stats",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getStats.fulfilled, (state, action) => {
      return {
        ...state,
        stats: action.payload.data,
      };
    });
    builder.addCase(getVehiculesStats.fulfilled, (state, action) => {
      const groupedByVehicule = _.groupBy(action.payload.data, "vehicule_code");

      return {
        ...state,
        statsByVehicules: groupedByVehicule,
      };
    });
  },
});

export const stats = (state: RootState) => state.stats.stats;
export const vehiculeStats = (state: RootState) => state.stats.statsByVehicules;

export default statsSlice.reducer;
