import React, { useState } from "react";

interface TimelineData {
  [key: string]: {
    user: string;
    heure_debut: string;
    heure_fin: string;
    sum_bls: number;
    count_bls: number;
    nbr_visite: number;
  };
}

const VehicleTimeline = ({ data }: { data: TimelineData }) => {
  const [openSections, setOpenSections] = useState<{ [key: string]: boolean }>(
    {}
  );

  const toggleSection = (key: string) => {
    setOpenSections((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  return (
    <div className="page">
      <div
        className="uia-timeline"
        data-uia-timeline-skin="2"
        data-uia-timeline-adapter-skin-2="ui-card-skin-#1"
        data-uia-card-skin="1"
        data-uia-card-mod="1"
      >
        <div className="uia-timeline__container">
          <div className="uia-timeline__line"></div>
          <div className="uia-timeline__groups">
            {data && Object.entries(data).length > 0 ? (
              Object.entries(data).map(([key, value]) => (
                <div key={key} className="uia-timeline__group">
                  <div
                    className="uia-timeline__dot"
                    onClick={() => toggleSection(key)}
                    style={{ cursor: "pointer" }}
                  ></div>
                  <div
                    className="uia-timeline__label"
                    onClick={() => toggleSection(key)}
                    style={{ cursor: "pointer", fontWeight: "bold" }}
                  >
                    <h5
                      id={`experience-step-${key}-heading`}
                      className="ra-heading"
                    >
                      {key} - {value.user}
                    </h5>
                    <span>
                      {`${value.heure_debut
                        .split(":")
                        .slice(0, 2)
                        .join(":")} - ${value.heure_fin
                        .split(":")
                        .slice(0, 2)
                        .join(":")}`}
                    </span>
                  </div>
                  {openSections[key] && (
                    <div className="uia-timeline__content">
                      <div className="uia-card">
                        <div className="uia-card__container">
                          <div className="uia-card__body">
                            <div className="profile-card-inf">
                              <div className="profile-card-inf__item">
                                <div className="profile-card-inf__title">
                                  {value.sum_bls}
                                </div>
                                <div className="profile-card-inf__txt">CA</div>
                              </div>
                              <div className="profile-card-inf__item">
                                <div className="profile-card-inf__title">
                                  {value.count_bls}
                                </div>
                                <div className="profile-card-inf__txt">
                                  Nombre de BLS
                                </div>
                              </div>
                              <div className="profile-card-inf__item">
                                <div className="profile-card-inf__title">
                                  {value.nbr_visite}
                                </div>
                                <div className="profile-card-inf__txt">
                                  Visites
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ))
            ) : (
              <p>No timeline data available.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VehicleTimeline;
