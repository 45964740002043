
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


const EmptyLayout = () => {
  return (
    <div className="main-wrapper">
      <ToastContainer />

      <div className="page-wrapper">
        <div className="page-content" style={{padding : 0}}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default EmptyLayout;
