import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../_App/Redux/hooks";

import _ from "lodash";

import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

import {
  ListDepots,
  ListVehiculesInAndOut,
} from "../../_App/Redux/Slices/vehicules/vehiculesSlice";
import { useTranslation } from "react-i18next";

const VehiculesInAndOut = () => {
  const { t } = useTranslation();
  const InAndOutData = useAppSelector(ListVehiculesInAndOut);
  const depots = useAppSelector(ListDepots);
  const depotsIn = _.groupBy(InAndOutData.vehiclesIn, "depot_code");
  const depotsOut = _.groupBy(InAndOutData.vehiclesOut, "depot_code");
  const [selectedTopic, setSelectedTopic] = useState<any>({});
  const [reload, setReload] = useState(false);

  useEffect(() => {
    if (InAndOutData && InAndOutData.inHistory && InAndOutData.outHistory) {
      // Flatten the arrays
      const flattenArray = (array: any) => array.flat();

      const defaultData = [
        ...flattenArray(Object.values(InAndOutData.inHistory)),
        ...flattenArray(Object.values(InAndOutData.outHistory)),
      ];
      console.log(defaultData);
      setSelectedTopic({
        title: "Tous vos vehicules",
        data: defaultData,
      });
    }
  }, [InAndOutData, reload]);

  return (
    <>
      <div className="row content-card">
        <div className="col-12 col-xl-12 stretch-card">
          <div className="col-md-12">
            <div className="row" style={{ padding: "0px 12px" }}>
              {depots &&
                depots.map((item, index) => (
                  <div className="col-6 mb-3">
                    <div className="card">
                      <div className="card-body">
                        <div className="d-flex justify-content-between align-items-baseline">
                          <h6 className="mb-0 text-primary">{item.libelle}</h6>
                        </div>
                        <div className="row mt-2">
                          <div className="col-6 col-md-12 col-xl-6">
                            <div
                              className="badge in"
                              onClick={() =>
                                setSelectedTopic({
                                  title: `Entées : ${item.libelle}`,
                                  data: InAndOutData.inHistory[item.code],
                                })
                              }
                            >
                              <i className="fas fa-truck"></i>
                              {item && item.code && depotsIn[item.code]?.length
                                ? depotsIn[item.code]?.length
                                : 0}{" "}
                              entrées
                            </div>
                          </div>
                          <div className="col-6 col-md-12 col-xl-6">
                            <div
                              className="badge out"
                              onClick={() =>
                                setSelectedTopic({
                                  title: `Sorties : ${item.libelle}`,
                                  data: InAndOutData.outHistory[item.code],
                                })
                              }
                            >
                              <i className="fas fa-truck"></i>{" "}
                              {item && item.code && depotsOut[item.code]?.length
                                ? depotsOut[item.code]?.length
                                : 0}{" "}
                              sorties
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>

            <div className="col-12 mb-3">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-baseline">
                    <h6 className="mb-0 text-primary">{selectedTopic.title}</h6>
                    <button
                      className="btn btn-info"
                      onClick={() => setReload(!reload)}
                    >
                      Actualiser
                    </button>
                  </div>
                  <div className="table-responsive ">
                    <table className="table table-hover mb-0">
                      <thead>
                        <tr className="fs-10">
                          <th rowSpan={3}>{t("")}</th>
                          <th colSpan={10} style={{ textAlign: "center" }}>
                            {t("Mouvement")}
                          </th>
                        </tr>
                        <tr>
                          <td
                            colSpan={5}
                            style={{
                              textAlign: "center",
                              borderTop: 0,
                              borderBottomWidth: "1px",
                              fontWeight: "bold",
                              fontSize: "12px",
                              textTransform: "uppercase",
                              color: "#686868",
                            }}
                          >
                            {t("Mouvement precedent")}
                          </td>
                          <td
                            colSpan={5}
                            style={{
                              textAlign: "center",
                              borderTop: 0,
                              borderBottomWidth: "1px",
                              fontWeight: "bold",
                              fontSize: "12px",
                              textTransform: "uppercase",
                              color: "#686868",
                            }}
                          >
                            {t("Dernier mouvement")}
                          </td>
                        </tr>
                        <tr>
                          <td>Sens</td>
                          <td>Date</td>
                          <td>Depot</td>
                          <td>Chauffeur</td>
                          <td>
                            <i className="fas fa-images"></i>
                          </td>
                          <td>Sens</td>
                          <td>Date</td>
                          <td>Depot</td>
                          <td>Chauffeur</td>

                          <td>
                            {" "}
                            <i className="fas fa-images"></i>
                          </td>
                        </tr>
                      </thead>

                      <tbody>
                        {selectedTopic?.data?.map((item: any, key: any) => (
                          <tr
                            key={key}
                            className={
                              key % 2 === 1
                                ? "fs-12 bg-azure-lightest "
                                : "fs-12 "
                            }
                          >
                            <td>
                              {item.current?.marque +
                                " " +
                                item.current?.num_serie}
                            </td>
                            <td className="">
                              {item.previous_record?.mouvement_libelle}
                            </td>
                            <td>
                              {
                                item.previous_record?.date
                                  .toString()
                                  .split("T")[0]
                              }
                            </td>
                            <td>{item.previous_record?.libelle}</td>
                            <td>{item.previous_record?.nom}</td>
                            <td>
                              {" "}
                              <Zoom>
                                <img
                                  alt=""
                                  src={
                                    item.previous_record?.path
                                      ? item.previous_record.path
                                      : null
                                  }
                                  width="100"
                                />
                              </Zoom>
                            </td>
                            <td className="">
                              {item.current.mouvement_libelle}
                            </td>
                            <td>
                              {item.current.date.toString().split("T")[0]}
                            </td>
                            <td>{item.current.libelle}</td>
                            <td>{item.current.nom}</td>
                            <td>
                              <Zoom>
                                <img
                                  alt=""
                                  src={
                                    item && item.current && item.current?.path
                                      ? item.current.path
                                      : "/assets/assets/img/no-image.jpg"
                                  }
                                  width="100"
                                />
                              </Zoom>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                {/* <div className="card-footer">
                  <p className="text-muted">
                    <span
                      className="text-primary"
                      style={{
                        float: "right",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={() => (window.location.href = "/vehicules")}
                    >
                      {" "}
                      <b className="text-primary">{t("Voir vehicules")}</b>{" "}
                      <i className="fa fas fa-arrow-right text-primary"></i>{" "}
                    </span>
                  </p>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-12 col-xl-12 stretch-card">
        <div className="col-6"></div>
        <div className="col-6"></div>
      </div>
    </>
  );
};

export default VehiculesInAndOut;
