import React, { useEffect, useState, useTransition } from "react";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { currentSousSociete } from "../../_App/Redux/Slices/auth/authSlice";
import {
  findhistoriqueVehicule,
  findVehicules,
  HistoriqueVehiculesParVehicule,
  ListVehiculesParCode,
} from "../../_App/Redux/Slices/vehicules/vehiculesSlice";
import { useParams } from "react-router-dom";
import {
  findPayHistory,
  ListPayHistoryByCode,
  ListPayHistoryBypaid,
} from "../../_App/Redux/Slices/payHistory/payHistorySlice";
import {
  getStats,
  getVehiculesStats,
  vehiculeStats,
} from "../../_App/Redux/Slices/stats/statsSlice";
import moment from "moment";
import { convertVehicleStatusToDataset } from "../../_App/Helpers/helpers";
import DatePicker from "react-datepicker";
import { fr } from "date-fns/locale";
import { useTranslation } from "react-i18next";
import QRCodeModal from "./QRCodeModal";
interface statsItem {
  vehicule_code: number;
  total_assurance: number;
  total_taxe: number;
  total_visite: number;
  total_entretien: number;
  total_en_panne: number;
  total_en_marche: number;
  total_en_reparation: number;
  total_au_parking: number;
}

interface HistoryItem {
  date: string;
  payment?: string;
  status?: string;
}

interface PaymentItem {
  type: string;
  cout: number;
}

const VehiculeHistory: React.FC = () => {
  const { vehicule } = useParams<{ vehicule: string }>();
  const dispatch = useAppDispatch();
  const soussociete = useAppSelector(currentSousSociete);
  const history = useAppSelector(HistoriqueVehiculesParVehicule);
  const payments = useAppSelector(ListPayHistoryBypaid);
  const [startDate, setStartDate] = useState<any>(
    moment().startOf("month").format("YYYY-MM-DD")
  ); // Start of current month
  const [endDate, setEndDate] = useState<any>(
    moment().endOf("month").format("YYYY-MM-DD")
  ); // End of current month

  const costsStats = useAppSelector(vehiculeStats);
  const vehicules = useAppSelector(ListVehiculesParCode);
  const [showQrCodeModal, setShowQrCodeModal] = useState(false);

  const { t } = useTranslation();
  useEffect(() => {
    if (soussociete && vehicule) {
      dispatch(findhistoriqueVehicule(null));
      dispatch(findPayHistory(soussociete));
      dispatch(
        getVehiculesStats({
          start: startDate,
          end: endDate, 
          id: vehicule,
        })
      );
      dispatch(findVehicules(soussociete));
    }
  }, [dispatch, soussociete, vehicule]);

  const sortedHistory =
    vehicule &&
    history[vehicule]?.slice().sort((a: HistoryItem, b: HistoryItem) => {
      return new Date(b.date).getTime() - new Date(a.date).getTime(); // Sort descending by date
    });
  const CustomInput = React.forwardRef(({ value, onClick }: any, ref: any) => (
    <input
      className="date-input"
      value={value}
      onClick={onClick}
      readOnly
      ref={ref}
    />
  ));
  return (
    <div>
      <QRCodeModal
        show={showQrCodeModal}
        setShow={setShowQrCodeModal}
        item={vehicule}
      />
      <nav className="navbar search-bar">
        <a href="#" className="sidebar-toggler">
          <i data-feather="menu" />
        </a>
        <div className="navbar-content">
          <form className="search-form">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">
                  <i data-feather="search" />
                </div>
              </div>
              {/* Uncomment and implement search functionality if needed */}
              {/* <input
                type="text"
                className="form-control"
                id="navbarForm"
                placeholder="Chercher ..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              /> */}
            </div>
          </form>
        </div>
      </nav>
      <nav className="page-breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="#">Vehicules</a>
          </li>
        </ol>
      </nav>

      <div className="row content-card">
        <div className="col-8"></div>
        <div className="col-4 mb-2" style={{ marginLeft: "84%" }}>
          <button
            className="btn btn-info"
            onClick={() => setShowQrCodeModal(true)}
          >
            Imprimer un code QR
          </button>
        </div>
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <i className="mdi mdi-tune-vertical text-primary" />
              Vehicule
            </div>
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-baseline">
                <h6
                  className="mb-0 text-primary"
                  onClick={() =>
                    console.log(convertVehicleStatusToDataset(history))
                  }
                ></h6>
              </div>
              <div className="table-responsive ">
                <div
                  className="date-filter mt-2"
                  style={{ display: "flex", float: "right" }}
                >
                  <div
                    className="pb-2 mr-5"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <DatePicker
                      selected={startDate}
                      onChange={(date) =>
                        setStartDate(date?.toISOString().split("T")[0])
                      }
                      dateFormat="yyyy-MM-dd"
                      className="date-input"
                      locale={fr}
                      customInput={<CustomInput />}
                    />
                  </div>
                  <div
                    className="pb-2"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <DatePicker
                      selected={endDate}
                      onChange={(date) =>
                        setEndDate(date?.toISOString().split("T")[0])
                      }
                      dateFormat="yyyy-MM-dd"
                      className="date-input"
                      locale={fr}
                      customInput={<CustomInput />}
                    />
                  </div>
                </div>
                <table className="table table-hover mb-0 stats-tab">
                  <thead>
                    <tr className="fs-10">
                      <th className="single">{t("Vehicule")}</th>

                      <th
                        colSpan={4}
                        style={{ borderRight: "2px solid #e8ebf1" }}
                      >
                        {t("Coûts")}
                      </th>

                      <th className="single">{t("Total")}</th>
                    </tr>
                    <tr className="fs-10">
                      <th></th>

                      <th>{t("assurances")}</th>
                      <th>{t("visites techniques")}</th>
                      <th>{t("entretiens")}</th>
                      <th style={{ borderRight: "2px solid #e8ebf1" }}>
                        {t("Autres")}
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {costsStats &&
                    vehicule &&
                    costsStats[vehicule] &&
                    costsStats[vehicule].length > 0 ? (
                      costsStats[vehicule].map((item: statsItem, key: any) => {
                        // Calculate total costs for the current row
                        const totalCosts =
                          (item.total_assurance || 0) +
                          (item.total_visite || 0) +
                          (item.total_entretien || 0) +
                          (item.total_taxe || 0);

                        return (
                          <tr
                            key={item.vehicule_code} // Assuming sous_societe is unique
                            className={`fs-12 ${
                              key % 2 === 1 ? "bg-azure-lightest" : ""
                            }`}
                          >
                            <td>
                              {vehicules &&
                              vehicules[item.vehicule_code] &&
                              vehicules[item.vehicule_code][0]
                                ? vehicules[item.vehicule_code][0].marque +
                                  " " +
                                  vehicules[item.vehicule_code][0].num_serie
                                : null}
                            </td>

                            <td className="text-primary">
                              {item.total_assurance || 0}
                            </td>
                            <td className="text-primary">
                              {item.total_visite || 0}
                            </td>
                            <td className="text-primary">
                              {item.total_entretien || 0}
                            </td>
                            <td className="text-primary">
                              {item.total_taxe || 0}
                            </td>
                            <td className="text-primary">{totalCosts}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={10}>No data available</td>
                      </tr>
                    )}
                  </tbody>
                  {/* <tfoot>
                    <tr className="fs-12">
                      <td>
                        <b>{t("Total")}</b>
                      </td>
                      <td className="text-danger">
                        <b>{totalEnPanne}</b>
                      </td>
                      <td className="text-danger">
                        <b>{totalEnMarche}</b>
                      </td>
                      <td className="text-danger">
                        <b>{totalEnReparation}</b>
                      </td>
                      <td className="text-danger">
                        <b>{totalAuParking}</b>
                      </td>
                      <td className="text-danger">
                        <b>{totalAssurances}</b>
                      </td>
                      <td className="text-danger">
                        <b>{totalVisitesTechniques}</b>
                      </td>
                      <td className="text-danger">
                        <b>{totalEntretiens}</b>
                      </td>
                      <td className="text-danger">
                        <b>{totalAutres}</b>
                      </td>
                      <td className="text-danger">
                        <b>{totalOverallCosts}</b>
                      </td>
                    </tr>
                  </tfoot> */}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row content-card">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <i className="mdi mdi-tune-vertical text-primary" /> Historique
              vehicule
            </div>
            <div className="card-body">
              <div>
                {vehicule && (
                  <ul className="timeline">
                    {sortedHistory?.map((item: HistoryItem, index: number) => (
                      <li
                        className="event"
                        data-date={item.date}
                        key={index}
                        onClick={() => {
                          if (item && item.payment)
                            console.log(item.payment, payments);
                        }}
                      >
                        <h6>
                          {item.payment && payments[item.payment]?.[0]
                            ? `${payments[item.payment][0].type} : ${
                                payments[item.payment][0].cout
                              } TND`
                            : item.status}
                        </h6>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VehiculeHistory;
