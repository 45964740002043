import img from "./vehicule.png";
import { currentSousSociete } from "../../_App/Redux/Slices/auth/authSlice";
import {
  findhistoriqueVehicule,
  findVehicules,
  HistoriqueVehiculesParVehicule,
  ListVehiculesParCode,
} from "../../_App/Redux/Slices/vehicules/vehiculesSlice";
import {
  findPayHistory,
  ListPayHistoryByCode,
} from "../../_App/Redux/Slices/payHistory/payHistorySlice";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import {
  getVehiculesStats,
  vehiculeStats,
} from "../../_App/Redux/Slices/stats/statsSlice";
import { useTranslation } from "react-i18next";
import { VehiculeType } from "../../_App/Types/Entites/VehiculeType";
import LOader from "../../_Layouts/LOader";
import { toast } from "react-toastify";
import axios from "axios";
import VehicleTimeline from "./VehiculeSalesTimeline";
import VehicleTimelineHistory from "./VehiculeSalesTimelineHistory";
interface HistoryItem {
  date: string;
  payment?: string;
  status?: string;
}

interface TimelineData {
  [key: string]: {
    user: string;
    heure_debut: string;
    heure_fin: string;
    sum_bls: number;
    count_bls: number;
    nbr_visite: number;
    sum_commandes: number;
    date: any;
  };
}
const DetailsVehicule = () => {
  const { soussociete, vehicule } = useParams();
  const dispatch = useAppDispatch();
  const history = useAppSelector(HistoriqueVehiculesParVehicule);
  const vehicules = useAppSelector(ListVehiculesParCode);
  const [vehiculeInfo, setVehiculeInfo] = useState<undefined | any>(undefined);
  const [stats, setStats] = useState<undefined | any>(undefined);

  const [loading, setLoading] = useState(true);
  function groupDataByDate(entetes: any, visites: any) {
    const combinedData: any = {};

    // Merge data from entetes
    for (const date in entetes) {
      if (!combinedData[date]) {
        combinedData[date] = {};
      }
      combinedData[date] = {
        ...combinedData[date],
        ...entetes[date],
      };
    }

    // Merge data from visites
    for (const date in visites) {
      if (!combinedData[date]) {
        combinedData[date] = {};
      }
      combinedData[date] = {
        ...combinedData[date],
        ...visites[date],
      };
    }

    return combinedData;
  }
  const [sales, setsales] = useState<undefined | TimelineData>(undefined);
  const kilometrage = async (code_depot: any) => {
    try {
      const response = await axios.get(
        `https://api.demo.clediss.online/api/v1/KilometragesInfo/${code_depot}`
      );
      const data = await response.data;
      if (data) {
        return setsales(groupDataByDate(data.entetes, data.visites));
      } else {
        toast.error("failed");
        return "";
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Error");
      return "";
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        if (soussociete && vehicule) {
          await dispatch(findhistoriqueVehicule(null));
          await dispatch(findPayHistory("Tous"));
          const res = await dispatch(
            getVehiculesStats({
              start: moment().startOf("month").format("YYYY-MM-DD"),
              end: moment().endOf("month").format("YYYY-MM-DD"),
              id: vehicule,
            })
          );
          console.log(res.payload.data);
          if (res.payload.success) setStats(res.payload.data[0]);

          await dispatch(findVehicules("Tous"));
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch, soussociete, vehicule]);
  useEffect(() => {
    if (vehicule && vehicules && vehicules[vehicule]?.[0]) {
      setVehiculeInfo(vehicules[vehicule][0]);
      kilometrage(vehicules[vehicule][0].code_depot);
    }
  }, [vehicule, vehicules]);

  const sortedHistory =
    vehicule &&
    history[vehicule]?.slice().sort((a: HistoryItem, b: HistoryItem) => {
      return new Date(b.date).getTime() - new Date(a.date).getTime(); // Sort descending by date
    });
  const CustomInput = React.forwardRef(({ value, onClick }: any, ref: any) => (
    <input
      className="date-input"
      value={value}
      onClick={onClick}
      readOnly
      ref={ref}
    />
  ));
  const [showTimeline, setShowTimeline] = useState(false);
  const timelineRef = useRef<HTMLDivElement>(null);

  const toggleTimeline = () => {
    setShowTimeline((prev) => !prev);
  };
  const [showTimelineHis, setShowTimelineHis] = useState(false);
  const timelineRefHis = useRef<HTMLDivElement>(null);

  const toggleTimelineHis = () => {
    setShowTimelineHis((prev) => !prev);
  };

  const currentMonth = new Date().getMonth(); // Get current month (0-11)
  const currentYear = new Date().getFullYear(); // Get current year
  return (
    <div>
      {loading ? (
        <LOader />
      ) : (
        <div className="wrapper">
          <div className="profile-card js-profile-card">
            <div className="profile-card__img">
              <img src={img} alt="profile card" />
            </div>

            <div className="profile-card__cnt js-profile-cnt">
              <div
                className="profile-card__name"
                onClick={() => console.log(vehiculeInfo)}
              >
                {vehiculeInfo?.marque + " " + vehiculeInfo?.num_serie}
              </div>
              <div className="profile-card__txt">
                {vehiculeInfo?.sous_societe_nom} <strong> </strong>
              </div>
              <div className="profile-card-loc">
                <span
                  className={
                    "profile-card-loc__txt badge " +
                    (vehiculeInfo?.status.toUpperCase() === "EN MARCHE" ||
                    vehiculeInfo?.status.toUpperCase() === "AU PARKING"
                      ? "badge-success"
                      : "badge-danger")
                  }
                >
                  {vehiculeInfo?.status.toUpperCase()}
                </span>
              </div>
              <div className="container mt-2">
                <div className="row mb-3">
                  <div className="col-6 d-flex justify-content-center align-items-center p-0">
                    <div className="color-box bg-danger text-white">
                      <p>Pannes</p>
                      <p>
                        {stats?.total_en_panne ? stats?.total_en_panne : "0"}{" "}
                      </p>
                    </div>
                  </div>
                  <div className="col-6 d-flex justify-content-center align-items-center p-0">
                    <div className="color-box bg-info text-white">
                      <p>Entretiens</p>
                      <p>
                        {stats?.total_entretien
                          ? stats?.total_entretien + " TND"
                          : "0 TND"}{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-6 d-flex justify-content-center align-items-center p-0">
                    <div className="color-box bg-primary text-white">
                      <p onClick={() => console.log(JSON.stringify(sales))}>
                        Ventes
                      </p>
                      <p>
                        {sales
                          ? Object.values(sales)
                              .filter((record) => {
                                const recordDate = new Date(record.date); // Convert record.date to Date object
                                return (
                                  recordDate.getMonth() === currentMonth &&
                                  recordDate.getFullYear() === currentYear
                                );
                              })
                              .reduce((sum, record) => sum + record.sum_bls, 0)
                              .toFixed(3)
                          : "Loading..."}
                      </p>
                    </div>
                  </div>
                  <div className="col-6 d-flex justify-content-center align-items-center p-0">
                    <div className="color-box bg-warning text-white">
                      <p onClick={() => console.log(JSON.stringify(sales))}>
                        BLS
                      </p>
                      <p>
                        {sales
                          ? Object.values(sales)
                              .filter((record) => {
                                const recordDate = new Date(record.date); // Convert record.date to Date object
                                return (
                                  recordDate.getMonth() === currentMonth &&
                                  recordDate.getFullYear() === currentYear
                                );
                              })
                              .reduce(
                                (sum, record) => sum + record.count_bls,
                                0
                              )
                          : "Loading..."}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <button
                  className="btn btn-primary m-2"
                  style={{ width: "100%" }}
                  onClick={toggleTimelineHis}
                >
                  Historique Vehicule
                </button>
              </div>
              <div
                className="timeline-wrapper"
                style={{
                  maxHeight: showTimelineHis
                    ? `${timelineRefHis.current?.scrollHeight || 0}px`
                    : "0",
                  overflow: "hidden",
                  transition: "max-height 0.5s ease-out",
                }}
                ref={timelineRefHis}
              >
                {history && vehicule && history[vehicule] && (
                  <VehicleTimelineHistory data={history[vehicule]} />
                )}
              </div>
              <div className="row">
                <button
                  className="btn btn-primary m-2"
                  style={{ width: "100%" }}
                  onClick={toggleTimeline}
                >
                  Historique de ventes
                </button>
              </div>
              <div
                className="timeline-wrapper"
                style={{
                  maxHeight: showTimeline
                    ? `${timelineRef.current?.scrollHeight || 0}px`
                    : "0",
                  overflow: "hidden",
                  transition: "max-height 0.5s ease-out",
                }}
                ref={timelineRef}
              >
                {sales && <VehicleTimeline data={sales} />}
              </div>

              {/* <div className="profile-card-ctr">
                <button className="profile-card__button button--blue js-message-btn">
                  button1
                </button>
                <button className="profile-card__button button--orange">
                  button2
                </button>
              </div> */}
            </div>

            {/* <div className="profile-card-message js-message">
              <form className="profile-card-form">
                <div className="profile-card-form__container">
                  <textarea placeholder="Say something..."></textarea>
                </div>

                <div className="profile-card-form__bottom">
                  <button className="profile-card__button button--blue js-message-close">
                    Send
                  </button>

                  <button className="profile-card__button button--gray js-message-close">
                    Cancel
                  </button>
                </div>
              </form>

              <div className="profile-card__overlay js-message-close"></div>
            </div> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default DetailsVehicule;
