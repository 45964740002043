import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import QRCode from "react-qr-code";
import { useAppSelector } from "../../_App/Redux/hooks";
import { currentSousSociete } from "../../_App/Redux/Slices/auth/authSlice";

const QRCodeModal = ({ show, setShow, item }: any) => {
  const handleClose = () => setShow(false);

  const soussociete = useAppSelector(currentSousSociete);

  const handlePrint = () => {
    const printContents = document.getElementById("qr-code-print-area")!.innerHTML;
    const originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  };

  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Generated QR Code</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            id="qr-code-print-area"
            style={{ textAlign: "center" }}
            onClick={() => (window.location.href = +"/detailsVehicule/" + item)}
          >
            <QRCode
              size={256}
              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
              value={
                document.location.origin +
                "/detailsVehicule/" +
                soussociete +
                "/" +
                item
              }
              viewBox={`0 0 256 256`}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handlePrint}>
            Print QR Code
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default QRCodeModal;
