import React from "react";

const LOader = () => {
  return (
    <div>
      <div className="arc"></div>
      <h1>
        <span>LOADING</span>
      </h1>
    </div>
  );
};

export default LOader;
