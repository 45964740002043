import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { SubmitHandler, useForm } from "react-hook-form";
import { Button, Modal } from "react-bootstrap";
import moment from "moment";
import { ListVehicules } from "../../_App/Redux/Slices/vehicules/vehiculesSlice";

import { toast } from "react-toastify";
import { VisiteTechType } from "../../_App/Types/Entites/VisiteTechType";
import {
  saveVisiteTechnique,
  updateVisiteTechnique,
} from "../../_App/Redux/Slices/visiteTech/visiteTechSlice";
import { files, saveFile } from "../../_App/Redux/Slices/files/filesSlice";
import { currentSousSociete } from "../../_App/Redux/Slices/auth/authSlice";

const VisiteTechniqueForm = ({
  selectedVisiteTechnique,
  showModal,
  setShowModal,
  setSelectedVisiteTechnique,
  load,
  setLoad,
}: any) => {
  const { t } = useTranslation();
  const vehicules = useAppSelector(ListVehicules);
  const currentSousSte = useAppSelector(currentSousSociete);

  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<VisiteTechType>();

  useEffect(() => {
    if (selectedVisiteTechnique) {
      reset(selectedVisiteTechnique);
    } else {
      reset({
        vehicule_code: "",
        date_visite: "",
        date_fin: "",
        status: "",
        motif: "",
        montant_TTC: 0,
        montant_HT: 0,
        piece_jointe: "",
        sous_societe: currentSousSte,
      });
    }
  }, [selectedVisiteTechnique, showModal]);

  const closeModal = () => {
    if (setSelectedVisiteTechnique !== null)
      setSelectedVisiteTechnique(undefined);
    setShowModal(false);
  };
  const [file, setFile] = useState<File | null>(null);
  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };
  const uploadFile = async () => {
    if (!file) return "";

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}files/upload`,
        {
          method: "POST",
          body: formData,
        }
      );
      const data = await response.json();
      if (data.success) {
        console.log("================>", data);
        return data.fileName;
      } else {
        toast.error("File upload failed");
        return "";
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      toast.error("Error uploading file");
      return "";
    }
  };

  const onSubmit: SubmitHandler<VisiteTechType> = async (data) => {
    if (selectedVisiteTechnique) {
      try {
        dispatch(
          updateVisiteTechnique({ id: selectedVisiteTechnique.id, data: data })
        );
        setLoad(!load);
        closeModal();
      } catch (error) {
        console.error("Error updating visite technique:", error);
      }
    } else {
      let res = await dispatch(saveVisiteTechnique(data));
      if (res.payload.success) {
        const fileName = await uploadFile(); // Await file upload

        let fileData = {
          code: res.payload.data.id,
          type: "visite_docs",
          path: fileName, // Use the full URL path returned by uploadFile
          // path: process.env.REACT_APP_API_URL_UPLOADS + fileName,
        };
        dispatch(saveFile(fileData));
        setLoad(!load);
        closeModal();
        toast.success("Visite technique ajoutée avec succès ! ");
      }
    }
  };
  const docs = useAppSelector(files);

  return (
    <Modal show={showModal} onHide={closeModal}>
      <Modal.Header>
        <Modal.Title>
          <span className="modal-title" id="addVisiteTechniqueLabel">
            <i className="mdi mdi-plus-circle text-primary mr-1" />
            {selectedVisiteTechnique
              ? t("MAJ Visite Technique")
              : t("Nouvelle Visite Technique")}
          </span>
        </Modal.Title>
        <button
          type="reset"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={(e) => {
            if (setSelectedVisiteTechnique !== null)
              setSelectedVisiteTechnique(undefined);
            setShowModal(false);
          }}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className="card-body">
          <form onSubmit={handleSubmit(onSubmit)}>
            {!selectedVisiteTechnique ? (
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <label htmlFor="file">{t("Fichier")}</label>
                    <input
                      type="file"
                      className="form-control"
                      onChange={onFileChange}
                    />
                  </div>
                </div>
              </div>
            ) : null}
            {selectedVisiteTechnique &&
              docs &&
              docs[selectedVisiteTechnique.id] &&
              docs[selectedVisiteTechnique.id][0] && (
                <div className="row mb-3">
                  <div className="col-12">
                    <a
                      href={
                        process.env.REACT_APP_API_URL_UPLOADS +
                        docs[selectedVisiteTechnique.id][0].path
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t("Télécharger le fichier associé")}
                    </a>
                  </div>
                </div>
              )}
            <div className="row">
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="vehicule_code">
                    {t("Vehicule")}
                    <span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-control"
                    {...register("vehicule_code")}
                  >
                    {vehicules.map((vehicule: any) => (
                      <option key={vehicule.code} value={vehicule.code}>
                        {vehicule.num_serie}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="date_visite">
                    {t("Date Visite")}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    {...register("date_visite")}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="date_fin">{t("Date Fin")}</label>
                  <input
                    type="date"
                    className="form-control"
                    {...register("date_fin")}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="status">{t("Status")}</label>
                  <input
                    type="text"
                    className="form-control"
                    {...register("status")}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="motif">{t("Motif")}</label>
                  <input
                    type="text"
                    className="form-control"
                    {...register("motif")}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="montant_TTC">{t("Montant TTC")}</label>
                  <input
                    type="number"
                    className="form-control"
                    {...register("montant_TTC")}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="montant_HT">{t("Montant HT")}</label>
                  <input
                    type="number"
                    className="form-control"
                    {...register("montant_HT")}
                  />
                </div>
              </div>
            </div>
            <Button
              variant="primary"
              type="submit"
              className="mr-2 float-right"
            >
              {t("Enregistrer")}
            </Button>
            <Button
              variant="secondary"
              className="mr-2 float-right"
              onClick={() => closeModal()}
            >
              {t("Annuler")}
            </Button>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default VisiteTechniqueForm;
